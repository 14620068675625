import React, { useState, useCallback } from "react";
import SequentialMode from "../../components/modos/graphic/index.js"
import VideoMode from "../../components/modos/video/index.js"
import FullScreenModal from "../../components/FullScreenModal/index.js"
import { functionsData } from "./helpers/functionsData.js";
import { validations } from "./helpers/functionsValidations.js";
import { useTranslation } from "react-i18next";
import { connect } from "../../store/index.js";

const modeToRender = (mode) => {
  switch (mode) {
    case "video":
      return VideoMode;
    case "graphic":
      return SequentialMode;
    default:
      return null;
  }
}


const TrainingForm = (props) => {
  const { t } = useTranslation("sessionStudio");
  const { open, handleClose, onSubmit, data } = props;
  const [trainningData, setTrainningData] = useState(null);
  const translateTraining = (lang) => "Entrenamiento con " + t(lang);
  const translateBlock = (lang) => "bloque de " + t(lang);
  const functions = functionsData(t, translateBlock, translateTraining);
  const titleModal = (mode) => {
    for (let i = 0; i < functions.length; i++)
      if (functions[i].mode === mode)
        return functions[i].name;
  }

  const validateAndSubmit = () => {
    if (validations[data.mode](trainningData, props.notify, t)) {
      data["params"] = trainningData;
      data["name"] = trainningData["name"];
      data["addsensor"] = trainningData["addsensor"];
      data["desc"] = trainningData["description"];
      data["files"] = trainningData["files"];
      if (data.mode === "video") data["videos"] = trainningData["videos"];
      onSubmit(data);
    }
  }

  const ModeComponent = useCallback(modeToRender(data?.mode), [data?.mode]);

  const title = data?.mode ? t("parameters") + " " + titleModal(data.mode) : "";
  return (
    <FullScreenModal
      open={open}
      handleClose={handleClose}
      title={title}
      submitButton={t("save")}
      onSubmit={validateAndSubmit}
      Content={
        <ModeComponent
          values={setTrainningData}
          data={data}
        />}
    />
  );
}

export default connect(TrainingForm);
